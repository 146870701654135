
































































import {Component, Prop, Vue} from 'vue-property-decorator';
import DeleteButton from '@/components/DeleteButton.vue';
import { EventBus } from '@/main';
import { updateObjectList } from '@/utils';
import {OutputRuleDTO} from "@/api";

@Component({
  components: {
    DeleteButton,
  },
})
export default class RulesList extends Vue {
  @Prop(Number) readonly ruleId!: number;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      value: 'name',
      align: 'left'
    },
    {
      text: 'Message Type',
      sortable: true,
      value: 'message_type',
      align: 'left',
    },
    {
      text: 'Device Type',
      value: 'device_type.name',
      align: 'left',
    },
    {
      text: 'Severity',
      sortable: true,
      align: 'left',
      value: 'severity',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ];

  public rules: OutputRuleDTO[]|null = null;
  public search: string = '';

  public async created() {
    EventBus.$on('rule', (data) => {
      this.rules = updateObjectList(this.rules, data);
    });
  }

  public async mounted() {
    this.updateRules();
  }

  public async updateRules() {
    this.rules = await this.$store.dispatch('rules/getAll')
  }

  public async removeRec(id) {
    await this.$store.dispatch('rules/delete', { ruleId: id });
  }

  get loading() {
    return this.rules === null;
  }
}
