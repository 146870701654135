var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Manage Rules")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/rules/create"}},[_vm._v("Create Rule")])],1),_c('v-card-subtitle',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Start typing to filter records...","single-line":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rules || [],"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.statusIcon",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[(item.status_level === 'error')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" error ")]):(item.status_level === 'warning')?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" warning ")]):(item.status_level === 'ok')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
                name: 'main-admin-rules-edit',
                params: {
                  ruleId: item.id,
                },
              }}},[_c('v-icon',[_vm._v("edit")])],1),_c('DeleteButton',{attrs:{"itemID":item.id,"deleteFunction":_vm.removeRec}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }